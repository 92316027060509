import './TitleCaption.scss'

export const TitleCaption = ({caption}) => {
  const getClassName = (index) => {
    if (index % 3 === 0) {
      return 'first-letter'
    } else if (index % 3 === 1) {
      return 'second-letter'
    } else if (index % 3 === 2) {
      return 'third-letter'
    }
  }


  return <p className='title-caption-wrapper'>
    {Array.from(caption).map((letter, index) =>
        <span key={index + letter} className={`letter ${getClassName(index)}`}>{letter}</span>
    )}
  </p>
}
