import { Header } from "../../components/Header/Header";
import {SectionDivider} from "../../components/SectionDivider/SectionDivider";
import {InviteToContact} from "../../components/InviteToContact/InviteToContact";
import {SliderAboutUs} from "../../components/SliderAboutUs/SliderAboutUs";

export const Home = () => {
  return <>
    <Header />

    <SectionDivider />

    <SliderAboutUs />

    <SectionDivider />

    <InviteToContact />

  </>
}
