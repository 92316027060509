import banner from "../../assets/Baner_1920x1080.png";
import './Header.css'

export const Header = () => {

  return <header className="header-wrapper">
    <div className='header-banner'>
      <img className='header-banner' src={banner} alt="Bajkowy Globus przedszkole logo" />
      <div className='header-banner-gradient'/>
    </div>
    <i
        className="fa-solid fa-bars"
    />
  </header>
}
