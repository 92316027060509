import Modal from 'react-modal';
import {useLayoutEffect, useState} from "react";
import signet from "../../assets/sygnet.png";

import './RecruitmentDialog.scss'
import {TitleCaption} from "../TitleCaption/TitleCaption";
import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import anime from "animejs";

export const RecruitmentDialog = () => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const runAnimeHandler = () => {
    anime({
      targets: '.img-anime',
      translateX: [-50, 50],
      loop: true,
      direction: 'alternate',
      duration: 3000,
      easing: 'easeInOutSine',
      autoplay: true,
    });
  }

  useLayoutEffect(() => {
    setIsOpen(true)
    const timer = setTimeout(runAnimeHandler, 0);
    window.scrollTo(0, 0);
    document.body.classList.add('dialog-active');

    return () => clearTimeout(timer)
  }, [])


  const closeModal = () => {
    document.body.classList.remove('dialog-active');
    setIsOpen(false);
  }

  const goToRecruitmentPage = () => {
    closeModal()
    navigate('/rekrutacja')
  }

  return (
    <Modal isOpen={isOpen} className='recruitment-dialog-wrapper'>
      <div className='dialog-title'>
        <TitleCaption caption={'Uwaga'}/>
        <p className='text-bold'>Zapisy na rok przedszkolny 2024/2025 już trwają!</p>
      </div>
      <div className='image-dialog'>
        <img className='img-anime' src={signet} alt='Bajkowy Globus Sygnet'/>
      </div>
      <div>
        <p className='text-bold'>Wyślij swoje dziecko w podróż dookoła świata!</p>
        <button className='button-primary' onClick={goToRecruitmentPage}>Rekrutacja</button>
      </div>
      <FontAwesomeIcon className='close-icon' icon={faCircleXmark} color={'#20546c'} size={"2x"} onClick={closeModal}/>
    </Modal>
  )
}
