import slider1 from "../../assets/slider1.png";
import slider2 from "../../assets/slider2.png";
import slider3 from "../../assets/slider3.png";
import slider4 from "../../assets/slider4.png";
import slider5 from "../../assets/slider5.png";
import slider6 from "../../assets/slider6.png";

export const Slides = [
  {
    imageSrc: slider1,
    text: 'Przedszkole Niepubliczne o charakterze podróżniczym „Bajkowy Globus” pokaże Państwa dzieciom różne zakątki świata.'
  },
  {
    imageSrc: slider2,
    text: 'W naszej placówce rozwijamy dzieci prowadząc cykl warsztatów podróżniczych i spotkań tematycznych.'
  },
  {
    imageSrc: slider3,
    text: 'Zależy nam by mali odkrywcy otwierali się na świat w którym żyją i ludzi z różnych zakątków globu, by poznawali różne kuchnie, języki i tradycje.'
  },
  {
    imageSrc: slider4,
    text:'Wierzymy, że kształcenie w nurcie otwartości  pomoże przedszkolakom odkryć swoje pasje, zainteresowania, talenty, wzbudzi w nich ciekawość i nauczy tolerancji.'
  },
  {
    imageSrc: slider5,
    text: 'Wykwalifikowana kadra nauczycielska pomoże w wieloaspektowym rozwoju dzieci.'
  },
  {
    imageSrc: slider6,
    text: 'Szeroki program zajęć dodatkowych nie pozwoli  na nudę!'
  },
]
