import {SummaryContact} from "../../components/SummaryContact/SummaryContact";
import {TitleCaption} from "../../components/TitleCaption/TitleCaption";
import {
  GoogleMapKindergartenLocation
} from "../../components/GoogleMapKindergartenLocation/GoogleMapKindergartenLocation";
import './Contact.scss'
import sun from "../../assets/sun.png";

export const Contact = () => {
  return <div className='contact-page-wrapper'>
    <div className='sun-wrapper'>
      <img className={'img-sun'} src={sun} alt='Bajkowy globus słonce'/>
    </div>
    <section className='contact-details-wrapper'>
      <TitleCaption caption='Dane kontaktowe'/>

      <div className='section'>
        <SummaryContact />
      </div>
    </section>

    <section className='contact-details-wrapper'>
      <div className='title-wrapper'>
        <TitleCaption caption='Gdzie jesteśmy' />
      </div>
      <div className='section'>
        <div className='map-info-wrapper'>
          <GoogleMapKindergartenLocation />
        </div>
      </div>
    </section>
  </div>
}
