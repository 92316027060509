import './GoogleMapKindergartenLocation.scss'

export const GoogleMapKindergartenLocation = () => {

  return <div className="map-wrapper">
      <iframe title="location" height="100%" width="100%" id="gmap_canvas"
              src="https://maps.google.com/maps?q=czarnochowice%20540&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder="0"
              scrolling="no" marginHeight="0" marginWidth="0"></iframe>
  </div>
}
