import {Link, NavLink} from "react-router-dom";
import {useState} from "react";
import './Navbar.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faPhone, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/logov2.png";

export const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState()

  let activeStyle = {
    borderBottom: "2px solid #f4a005",
    color: "#f4a005",
  };

  const isActive = ({ isActive }) =>
      isActive ? activeStyle : undefined

  return <nav className='navbar-wrapper'>
    <div className="logo">
      <Link className={'navbar-link'} to="/">
        <img className='navbar-logo' src={logo} alt="Bajkowy Globus Przedszkole" />
      </Link>
    </div>
    <div className={'navbar-nav-wrapper'}>
      <nav className="navbar-nav">
        <FontAwesomeIcon icon={faBars} onClick={() => setIsExpanded(!isExpanded)} />
        <ul className={`collapsed ${isExpanded ? "is-expanded" : ""}`}>

          <NavLink className='navbar-nav-link'
                   to="/"
                   style={isActive}
                   onClick={() => setIsExpanded(!isExpanded)}
          >
            <span className='navbar-nav-caption'>O nas</span>
          </NavLink>
          <NavLink className='navbar-nav-link' to="/oferta" style={isActive} onClick={() => setIsExpanded(!isExpanded)}>
            <span className='navbar-nav-caption'>Oferta</span>
          </NavLink>
          <NavLink className='navbar-nav-link' to="/kontakt" style={isActive} onClick={() => setIsExpanded(!isExpanded)}>
            <span className='navbar-nav-caption'>Kontakt</span>
          </NavLink>
          <NavLink className='navbar-nav-link' to="/rekrutacja" style={isActive} onClick={() => setIsExpanded(!isExpanded)}>
            <span className='navbar-nav-caption'>Rekrutacja</span>
          </NavLink>
          <NavLink className='navbar-nav-link' to="/dokumenty" style={isActive} onClick={() => setIsExpanded(!isExpanded)}>
            <span className='navbar-nav-caption'>Dokumenty</span>
          </NavLink>

        </ul>
      </nav>
    </div>
    <div className="navbar-contact">
      <div className='navbar-contact-item'>
        <a href="tel:+48721718501">
          <FontAwesomeIcon icon={faPhone} color={'#20546c'} size={"1x"}/>
          <span className="navbar-contact-text">Tel: +48 721 718 501</span>
        </a>
      </div>
      <div className='navbar-contact-item'>
        <a href="mailto:bajkowyglobus@gmail.com">
          <FontAwesomeIcon icon={faEnvelope} color={'#20546c'} size={"1x"}/>
          <span className="navbar-contact-text">Email: bajkowyglobus@gmail.com</span>
        </a>
      </div>
    </div>
  </nav>

}
