import {Link} from "react-router-dom";

import './InviteToContact.scss'

export const InviteToContact = () => {
  return <section className='invite-to-contact-wrapper'>
    <div className='invite-container'>
      <h2 className='title'>W czym możemy pomóc?</h2>
      <p className='caption'>
        Jeśli są Państwo zainteresowani naszą oferta lub chcą uzyskać więcej informacji o placówce zachęcamy do kontaktu.
      </p>

      <div className='contact-button'>
        <Link className='link' to='/kontakt'>Kontakt</Link>
      </div>

    </div>
  </section>
}
