import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SliderAboutUs.scss'
import {SliderElement} from "./SliderElement";

import {TitleCaption} from "../TitleCaption/TitleCaption";
import {faAngleRight, faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Slides} from "./Slides.constants";
// import cloud from "../../assets/cloud_blue.png";
// import indian from "../../assets/indian_with_tent.png";
// import indian from "../../assets/japonka.png";

function NextArrow({onClick}) {
  return <FontAwesomeIcon className='slider-arrow slider-next-arrow' icon={faAngleRight} color={'#1f546c'} size={"2x"} onClick={onClick}/>
}

function PrevArrow({onClick}) {
  return <FontAwesomeIcon className='slider-arrow slider-prev-arrow' icon={faAngleLeft} color={'#1f546c'} size={"2x"} onClick={onClick}/>
}

export const SliderAboutUs = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // autoplay: true,
    autoplaySpeed: 4000
  };


  return <section className='slider-wrapper'>
    <div className='slider-title'>
      <TitleCaption caption='Kilka słów o placówce Bajkowy Globus'/>
    </div>
    <Slider {...settings}>
      {Slides.map((slide, index) => {
        return <SliderElement key={index + slide.text} imageSrc={slide.imageSrc} text={slide.text}/>
      })}
    </Slider>
  </section>
}
