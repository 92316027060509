import './App.css';
import { useEffect } from 'react';
import { GlobalRoutes } from "./routes/GlobalRoutes";
import { Navbar } from "./components/Navbar/Navbar";
import {ScrollToTop} from "./components/ScrollToTop/ScrollToTop";
import {Footer} from "./components/Footer/Footer";
import {RecruitmentDialog} from "./components/RecruitmentDialog/RecrutimentDialog";

function App() {
  useEffect(() => {
    document.title = 'Bajkowy Globus';
  });

  return (
    <div className="App">
      <ScrollToTop />
      <Navbar />
      <GlobalRoutes />
      <Footer />
      <RecruitmentDialog />
    </div>
  );
}

export default App;
