import { Routes, Route } from "react-router-dom";
import { Home } from "../containers/Home/Home";
import {Offer} from "../containers/Offer/Offer";
import {Contact} from "../containers/Contact/Contact";
import {Recruitment} from "../components/Recruitment/Recruitment";
import {Documents} from "../components/Documents/Documents";

export const GlobalRoutes = () => {
  return <Routes>
    <Route path={'/'} element={<Home />}/>
    <Route path={'/oferta'} element={<Offer />}/>
    <Route path={'/kontakt'} element={<Contact />}/>
    <Route path={'/rekrutacja'} element={<Recruitment />}/>
    <Route path={'/dokumenty'} element={<Documents />}/>
  </Routes>
}
